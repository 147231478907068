<div *ngIf="isSuperAdmin" class="impersonation-bar">
  Estás viendo este panel como:
  <p-dropdown 
    [options]="tenants" 
    [(ngModel)]="selectedTenantId" 
    optionLabel="name" 
    optionValue="id"
    placeholder="Selecciona un tenant" 
    (onChange)="onTenantChange($event.value)"
    [disabled]="!tenants.length">
  </p-dropdown>
</div>

<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
<div class="layout-container">
  <div class="flex flex-row header-wrapper">
    <p-menubar [model]="items" class="height">
      <ng-template pTemplate="start">
        <div class="flex flex-none align-items-center justify-content-center pl-3 tenant-logo mr-3">
          <img [src]="logoUrl" height="48px" [routerLink]="['/home']" />
        </div>
      </ng-template>
      <ng-template pTemplate="end">
        <div class="flex align-content-center flex-wrap card-container mr-6">
          <span class="logged-in" pTooltip="Status: Online" tooltipPosition="bottom">●</span>
          <button pButton type="button" pTooltip="Abrir web de venta" tooltipPosition="bottom"
            class="p-button-text mr-2" icon="pi pi-external-link" (click)="openPage()"></button>
          <button pButton type="button" pTooltip="Copiar enlace web" tooltipPosition="bottom" class="p-button-text mr-3"
            icon="pi pi-link" (click)="copyPageLink()"></button>
          <div class="initials" (click)="op.toggle($event)">
            {{getInitials(name)}}
          </div>
          <p-overlayPanel #op>
            <ng-template pTemplate>
              <div class="flex flex-column">
                <div class="flex align-items-center justify-content-start">
                  <h3>{{name}}</h3>
                </div>
                <div class=" mb-3">
                  <span class="mb-2 font-semibold">Tu plan</span>
                  <div>
                    <p-tag class="ml-2" [value]="tier"></p-tag>
                  </div>
                </div>
                <div>
                  <span class="mb-2 font-semibold">Almacenamiento</span>
                  <p-progressBar [value]="storageValue"></p-progressBar>
                  <p>0.00 GB utilizados de ∞</p>
                </div>
                <div>
                  <span class="mb-2 font-semibold">Comunicaciones</span>
                  <p-progressBar [value]="communicationsValue"></p-progressBar>
                  <p>0 enviadas de ∞</p>
                </div>
                <div>
                  <span class="mb-2 font-semibold">Segmentos</span>
                  <p-progressBar [value]="segmentsValue"></p-progressBar>
                  <p>0 creados de ∞</p>
                </div>

                <p-divider></p-divider>
                <div class="flex align-items-center justify-content-start">
                  <button pButton icon="pi pi-globe" type="button" label="Mi web" class="p-button-text w-full"
                    [routerLink]="['/web']"></button>
                </div>
                <div class="flex align-items-center justify-content-start">
                  <button pButton icon="pi pi-building" type="button" label="Mi organización"
                    class="p-button-text w-full" [routerLink]="['/settings']"></button>
                </div>
                <div class="flex align-items-center justify-content-start">
                  <button pButton icon="pi pi-angle-double-up" type="button" label="Mejora tu plan"
                    class="p-button-text w-full" (click)="openImprovePlan()"></button>
                </div>
                <div class="flex align-items-center justify-content-start">
                  <button pButton icon="pi pi-sign-out" type="button" label="Cerrar sesión" class="p-button-text w-full"
                    (click)="logout()"></button>
                </div>
              </div>

            </ng-template>
          </p-overlayPanel>
        </div>
      </ng-template>
    </p-menubar>
  </div>
  <!-- Breadcrumb -->
  <div class="breadcrumb-wrapper">
    <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
  </div>
  <div class="flex flex-row flex-grow-1 container">
    <div id="menu" class="flex-initial flex px-5 py-3">
      <!--<app-menu></app-menu>-->
    </div>
    <div id="content" class="flex-grow-1 flex">
      <div style="width: 100%" class="flex flex-column">
        <div id="outlet-container" class="p-5">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>