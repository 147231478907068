<div class="stepsdemo-content">
  <div class="flex flex-row mb-2">
    <button pButton class="p-button-primary cta-button" label="Crear entradas" (click)="addTicket()"
      icon="pi pi-plus"></button>
  </div>
  <p-card>
    <ng-template pTemplate="title">Entradas</ng-template>
    <ng-template pTemplate="subtitle" *ngIf="!ticketsSold">
      Introduce los datos de las entradas
    </ng-template>
    <ng-template pTemplate="subtitle" *ngIf="ticketsSold">
      No se pueden modificar los parámetros de las entradas porque ya se han
      comenzado a vender
    </ng-template>
    <ng-template pTemplate="content">
      <div
        class="flex flex-column"
        *ngIf="!eventId && data && data.length > 0"
      >
        <div *ngFor="let ticket of data; index as i">
          <app-ticket-item
            [index]="i"
            [eventId]="eventId"
            [ticket]="ticket"
            (onUpdate)="updateTicket($event)"
            (onRemove)="removeTicketFromList(i)"
          ></app-ticket-item>
          <p-divider></p-divider>
        </div>
      </div>
      
      <p-orderList 
        *ngIf="eventId && data && data.length > 0"
        [value]="data" 
        [dragdrop]="true" 
        [listStyle]="{'min-height':'300px'}"
        (onReorder)="onReorder($event)"
        [responsive]="true"
        class="w-full"
      >
        <ng-template let-ticket pTemplate="item">
          <app-ticket-item
            [index]="ticketIndex(ticket)"
            [eventId]="eventId"
            [ticket]="ticket"
            (onUpdate)="updateTicket($event)"
            (onRemove)="removeTicketFromList(ticketIndex(ticket))"
          ></app-ticket-item>
        </ng-template>
      </p-orderList>
      
      <p *ngIf="!data || data.length === 0">No hay entradas disponibles.</p>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-between">
        <div class="flex-none">
          <button
            pButton
            class="p-button-text"
            *ngIf="!eventId"
            label="Atrás"
            (click)="prevPage()"
            icon="pi pi-angle-left"
          ></button>
        </div>
        <div class="flex-grow-1"></div>
        <div>
          <button
            pButton
            class="p-button-text"
            *ngIf="!eventId"
            label="Guardar borrador"
            (click)="saveDraft()"
          ></button>
          <button
            pButton
            class="p-button-text"
            *ngIf="!eventId"
            [disabled]="!ticketForm.valid"
            label="Siguiente"
            (click)="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-card>
</div>

<p-dialog [header]="update ? 'Editar entrada' : 'Añadir entrada'" [(visible)]="display" [modal]="true">
  <form [formGroup]="ticketForm">
    <div class="field">
      <label for="name">Nombre *</label>
      <input
        id="name"
        formControlName="name"
        type="text"
        pInputText
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
      />
      <small
        *ngIf="
          this.ticketForm.get('name')?.invalid &&
          (this.ticketForm.get('name')?.dirty ||
            this.ticketForm.get('name')?.touched)
        "
        id="name-help"
        class="p-error block"
        >El nombre es obligatorio</small
      >
    </div>
    <div class="field">
      <label for="itemDescription">Descripción</label>
      <p-editor formControlName="description" [style]="{ height: '200px' }">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <select class="ql-header">
              <option value="1"></option>
              <option value="2"></option>
              <option value="3"></option>
              <option value="4"></option>
              <option value="5"></option>
              <option value="6"></option>
              <option value="false" selected></option>
            </select>
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button
              type="button"
              class="ql-italic"
              aria-label="Italic"
            ></button>
            <button
              type="button"
              class="ql-underline"
              aria-label="Underline"
            ></button>
            <button
              type="button"
              class="ql-strike"
              aria-label="Strike"
            ></button>
            <button type="button" class="ql-list" value="ordered"></button>
            <button type="button" class="ql-list" value="bullet"></button>
          </span> </ng-template
      ></p-editor>
    </div>
    <div class="field flex flex-column">
      <label for="image">Imagen</label> 
      <p-image *ngIf="this.ticketForm.get('imageUrl').value" [src]="this.ticketForm.get('imageUrl').value"
        alt="Image" width="250"></p-image>
      <p-fileUpload #fubauto mode="basic" name="myfile[]" accept="image/*" maxFileSize="1000000"
        [customUpload]="true" (uploadHandler)="myUploader($event)" [auto]="true"
        chooseLabel="Browse"></p-fileUpload>
      <p *ngIf="this.uploadedFiles.length > 0">
        Subido {{ this.uploadedFiles[0].name }} -
        {{ this.uploadedFiles[0].size }} bytes
      </p>
    </div>

    <div class="flex flex-row">
      <div class="field w-4">
        <label for="ticketsNumber">Número de entradas *</label>
        <p-inputNumber
          id="ticketsNumber"
          formControlName="ticketsNumber"
          [disabled]="this.update"
          class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full"
        ></p-inputNumber>
        <div
          *ngIf="
            this.ticketForm.get('ticketsNumber')?.invalid &&
            (this.ticketForm.get('ticketsNumber')?.dirty ||
              this.ticketForm.get('ticketsNumber')?.touched)
          "
          id="name-help"
        >
          <small
            class="p-error block"
            *ngIf="this.ticketForm.controls.ticketsNumber.errors?.required"
          >
            El número de entradas es obligatorio.
          </small>
          <small
            class="p-error block"
            *ngIf="this.ticketForm.controls.ticketsNumber.errors?.max"
          >
            Para crear más de
            {{ this.ticketForm.controls.ticketsNumber.errors?.max.max }} entradas
            contacta con soporte.
          </small>
        </div>
      </div>
      <div class="field">
        <label for="regularPrice">Precio de la entrada *</label>
        <app-price-input
          [defaultPrice]="defaultPrice"
          [includeTaxes]="includeTaxes"
          [fee]="ticketFee"
          tax="21"
          [disabled]="this.selectedTicketSold"
          (onPriceChange)="onTicketPriceChange($event)"
        ></app-price-input>
      </div>
    </div>
    <div class="field">
      <p *ngIf="update && this.selectedTicketSold" class="text-danger">El precio no se puede modificar en tickets con al menos una venta realizada.</p>
    </div>

    <div class="flex flex-row">
      <div class="field flex flex-column w-4">
        <label for="sellDate">Inicio de venta de entradas *</label>
        <p-calendar
          appendTo="body"
          id="sellDate"
          formControlName="sellDate"
          dateFormat="dd/mm/yy"
          (onSelect)="setMaxDate($event); setMinSellEndDate()"
          [showTime]="true"
          [maxDate]="startDate"
          [disabled]="ticketsSold"
          [showButtonBar]="true"
          placeholder="dd/MM/yyyy HH:mm"
        ></p-calendar>
      </div>

      <div class="field flex flex-column w-4">
        <label for="sellEndDate">Fin de venta de entradas</label>
        <p-calendar
          appendTo="body"
          id="sellEndDate"
          formControlName="sellEndDate"
          dateFormat="dd/mm/yy"
          [showTime]="true"
          [minDate]="minSellEndDate"
          [maxDate]="startDate"
          [disabled]="ticketsSold"
          [showButtonBar]="true"
          placeholder="dd/MM/yyyy HH:mm"
        ></p-calendar>
        <small
        *ngIf="
          ticketForm.hasError('sellEndDateInvalid') &&
          (ticketForm.get('sellEndDate')?.dirty ||
            ticketForm.get('sellEndDate')?.touched)
        "
        id="sellEndDate-help"
        class="p-error block"
        >La fecha de fin de venta debe ser posterior a la fecha de inicio de venta.</small
      >
      </div>
    </div>

    <h3>Fecha de validez</h3>
    <p>
      Configura el momento de inicio y fin en el que la entrada permanecerá válida (ejemplo: Entradas de día)
    </p>
    <div class="field-checkbox">
      <p-checkbox
        formControlName="hasValidityLimit"
        binary="true"
        label="Quiero que esta entrada tenga una fecha límite de validación"
        (onChange)="onHasValidityLimitChange($event)"
      ></p-checkbox>
    </div>
    
    <div *ngIf="ticketForm.get('hasValidityLimit').value">
      <div class="flex flex-row">
        <div class="field flex flex-column w-4">
          <label for="validityStartDate">Inicio de validez *</label>
          <p-calendar
            appendTo="body"
            id="validityStartDate"
            formControlName="validityStartDate"
            dateFormat="dd/mm/yy"
            [minDate]="startDate"
            [maxDate]="endDate"
            [defaultDate]="startDate"
            [showTime]="true"
            [showButtonBar]="true"
            placeholder="dd/MM/yyyy HH:mm"
          ></p-calendar>
          <small
            *ngIf="
              ticketForm.hasError('validityStartDateRequired') &&
              (ticketForm.get('validityStartDate')?.dirty ||
                ticketForm.get('validityStartDate')?.touched)
            "
            id="validityStartDate-help"
            class="p-error block"
          >La fecha de inicio de validez es obligatoria.</small>
        </div>
        <div class="field flex flex-column w-4">
          <label for="validityEndDate">Fin de validez *</label>
          <p-calendar
            appendTo="body"
            id="validityEndDate"
            formControlName="validityEndDate"
            dateFormat="dd/mm/yy"
            [minDate]="startDate"
            [maxDate]="endDate"
            [defaultDate]="endDate"
            [showTime]="true"
            [showButtonBar]="true"
            placeholder="dd/MM/yyyy HH:mm"
          ></p-calendar>
          <small
            *ngIf="
              ticketForm.hasError('validityEndDateRequired') &&
              (ticketForm.get('validityEndDate')?.dirty ||
                ticketForm.get('validityEndDate')?.touched)
            "
            id="validityEndDate-help"
            class="p-error block"
          >La fecha de fin de validez es obligatoria.</small>
          <small
            *ngIf="
              ticketForm.hasError('validityEndDateInvalid') &&
              (ticketForm.get('validityEndDate')?.dirty ||
                ticketForm.get('validityEndDate')?.touched)
            "
            id="validityEndDate-invalid-help"
            class="p-error block"
          >La fecha de fin de validez debe ser posterior a la fecha de inicio de validez.</small>
        </div>
      </div>
    </div>

    <h3>Ventajas para fans</h3>
    <p>Tus fans podrán tener acceso a estas ventajas</p>

    <div class="flex flex-row">
      <div class="field w-4">
        <label for="fanDiscount">Descuento (%)</label>
        <p-inputNumber
          id="fanDiscount"
          suffix="%"
          formControlName="fanDiscount"
          [disabled]="ticketsSold"
          class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full"
        ></p-inputNumber>
      </div>
      <div class="field w-4">
        <label for="fanSellDate">Fecha de venta anticipada</label>
        <div>
          <p-calendar
            appendTo="body"
            id="fanSellDate"
            formControlName="fanSellDate"
            dateFormat="dd/mm/yy"
            [maxDate]="maxSellDate"
            [showTime]="true"
            [disabled]="ticketsSold"
            [showButtonBar]="true"
            placeholder="dd/MM/yyyy HH:mm"
          ></p-calendar>
        </div>
      </div>
    </div>
    <app-step-tickets-assignation></app-step-tickets-assignation>

    <h3>Personalización</h3>
    <p class="sub">Configura las opciones de personalización para esta entrada.</p>
    <div class="field-checkbox">
      <p-checkbox
        formControlName="personalizationRequired"
        binary="true"
        label="Para adquirir esta entrada es obligatorio seleccionar una personalización con la misma."
      ></p-checkbox>
    </div>

  </form>
  <ng-template pTemplate="footer">
    <button
      pButton
      *ngIf="!creatingTicket; else createBlocked"
      [disabled]="!ticketForm.valid || !ticketForm.dirty || !ticketForm.touched"
      class="p-button-success"
      [label]="update ? 'Guardar cambios' : 'Añadir entrada'"
      (click)="createTicket()"
    ></button>
    <ng-template #createBlocked>
      <button
        pButton
        [disabled]="true"
        class="p-button-success"
        label="Añadir entrada"
        icon="pi pi-spin pi-spinner"
        iconPos="right"
      ></button>
    </ng-template>
  </ng-template>
</p-dialog>
