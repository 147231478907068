<div class="card h-full">
  <div class="card-container justify-content-start p-3">
    <div class="block border-round mb-3 table-container">
      <p-table #usersTable [value]="users" selectionMode="single" [(selection)]="selectedUser"
        (onRowSelect)="openUser($event)" styleClass="p-datatable-sm"
        [globalFilterFields]="['name', 'email', 'phone', 'state']">
        <ng-template pTemplate="caption">
          <div class="flex">

            <button pButton label="Borrar filtros" class="p-button-outlined ml-3" icon="pi pi-filter-slash"
              (click)="clear(usersTable)"></button>
            <span class="p-input-icon-left ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="
                  usersTable.filterGlobal($event.target.value, 'contains')
                " placeholder="Buscar" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 20%" pSortableColumn="name">
              Nombre
              <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th style="width: 30%" pSortableColumn="email">
              Email
              <p-columnFilter type="text" field="email" display="menu"></p-columnFilter><p-sortIcon
                field="email"></p-sortIcon>
            </th>
            <th style="width: 12.5%" pSortableColumn="phone">
              Teléfono
              <p-columnFilter type="text" field="phone" display="menu"></p-columnFilter><p-sortIcon
                field="phone"></p-sortIcon>
            </th>
            <th style="width: 12.5%" pSortableColumn="state">
              Estado
              <p-columnFilter field="state" matchMode="in" display="menu" [showMatchModes]="false"
                [showOperator]="false" [showAddButton]="false">
                <ng-template pTemplate="header">
                  <div class="px-3 pt-3 pb-0">
                    <span class="font-bold">Estado</span>
                  </div>
                </ng-template>
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect [ngModel]="value" [options]="states" placeholder="Any"
                    (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
                    <ng-template let-option pTemplate="item">
                      <div class="inline-block vertical-align-middle">
                        <span class="ml-1 mt-1">{{ option.name }}</span>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </ng-template> </p-columnFilter><p-sortIcon field="state"></p-sortIcon>
            </th>
            <th style="width: 12.5%" pSortableColumn="totalPurchases">
              Experiencias <p-sortIcon field="totalPurchases"></p-sortIcon>
            </th>
            <th style="width: 12.5%" pSortableColumn="totalIncome">
              Valor <p-sortIcon field="totalIncome"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template let-user pTemplate="body">
          <tr [pSelectableRow]="user">
            <td>{{ getFullName(user.name, user.surname1, user.surname2) }}</td>
            <td>{{ user?.email }}</td>
            <td>{{ user.phone == null ? "-" : user.phone }}</td>
            <td>
              <div class="flex flex-row align-items-center justify-content-center">
                <p-tag [value]="getState(user.state)"></p-tag>
              </div>
            </td>
            <td>{{ user.events && user.events.length }}</td>
            <td>
              {{
              user.totalIncome / 100 | currency : "EUR" : "symbol" : "1.2-2"
              }}
            </td>
            <td>
              <i class="pi pi-angle-right"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <p-speedDial [model]="items" direction="up" class="custom-speed-dial">
    <ng-template pTemplate="item" let-item>
      <button pButton [icon]="item.icon" class="custom-speed-dial-button"
        pTooltip="{{item.tooltipOptions.tooltipLabel}}"
        tooltipPosition="{{item.tooltipOptions.tooltipPosition}}"></button>
    </ng-template>
  </p-speedDial>
</div>

<app-import-dialog [displayImportDialog]="displayImportDialog" (hideDialog)="hideDialog('import')"></app-import-dialog>
<app-create-dialog [displayCreateDialog]="displayCreateDialog" (hideDialog)="hideDialog('create')"></app-create-dialog>