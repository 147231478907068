<div class="grid">
  <!-- Primera columna: Tabla de asistentes, siempre visible -->
  <div class="col-8 p-3 attendants-table-container"
    [ngClass]="{'col-12': !selectedAttendant, 'col-8': selectedAttendant}">
    <p-table #attendantsTable [value]="attendants" selectionMode="single" [(selection)]="selectedAttendant"
      (onRowSelect)="openUser($event)" responsiveLayout="scroll" scrollable="true" scrollHeight="flex"
      styleClass="p-datatable-sm" [globalFilterFields]="[
        'ticketId',
        'ticketTypeId',
        'email',
        'name',
        'purchasedAt',
        'validated'
      ]">
      <ng-template pTemplate="caption">
        <div class="flex">
          <button [disabled]="blockDownload" pButton pRipple type="button"
            [label]="selectedAttendant?'':'Exportar listado'" icon="pi pi-file-excel" (click)="exportList()"
            pTooltip="Exportar listado" tooltipPosition="bottom"></button>
          <button pButton pRipple class="ml-3" type="button" [label]="selectedAttendant?'': 'Invitar asistentes'"
            icon="pi pi-plus" (click)="showInviteDialog()" pTooltip="Invitar asistentes"
            tooltipPosition="bottom"></button>
          <button pButton label="Borrar filtros" class="p-button-outlined ml-3" icon="pi pi-filter-slash"
            (click)="clear(attendantsTable)"></button>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="
                attendantsTable.filterGlobal($event.target.value, 'contains')
              " placeholder="Buscar" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="email">
            Email <p-sortIcon field="email"></p-sortIcon>
            <p-columnFilter type="text" field="email" display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="name">
            Nombre <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
          </th>
          <th>
            Tipo
            <p-columnFilter field="ticketTypeId" matchMode="in" display="menu" [showMatchModes]="false"
              [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold">Tipo de entrada</span>
                </div>
              </ng-template>
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="ticketTypeOptions" placeholder="Any"
                  (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.name }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="purchasedAt" *ngIf="!selectedAttendant">
            Fecha <p-sortIcon field="purchasedAt"></p-sortIcon>
            <p-columnFilter type="text" field="purchasedAt" display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="validated" *ngIf="!selectedAttendant">
            Asistencia
            <p-sortIcon field="validated"></p-sortIcon>
            <p-columnFilter field="validated" matchMode="in" display="menu" [showMatchModes]="false"
              [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold">Validado</span>
                </div>
              </ng-template>
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="[
                    { id: true, name: 'Si' },
                    { id: false, name: 'No' }
                  ]" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.name }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th *ngIf="!selectedAttendant"></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-attendant>
        <tr [pSelectableRow]="attendant">
          <td>
            <a [routerLink]="['/users/' + attendant.userId]">{{ attendant.email }}</a>
          </td>
          <td>{{ attendant.fullName }}</td>
          <td>{{ attendant.ticketTypeName }}</td>
          <td *ngIf="!selectedAttendant" [@fadeInOut]>{{ attendant.purchasedAt | date : "short" }}</td>
          <td *ngIf="!selectedAttendant" [@fadeInOut]>
            <div class="flex justify-content-center">
              <i *ngIf="attendant.validated === true" [pTooltip]="attendant.validatedAt | date : 'dd/MM/yyyy HH:mm'"
                tooltipPosition="top" class="pi pi-check" style="color: green; font-weight: 600"></i>
              <i *ngIf="attendant.validated === false" class="pi pi-clock" style="color: orange; font-weight: 600"
                pTooltip="Pendiente" tooltipPosition="top"></i>
            </div>
          </td>
          <td *ngIf="!selectedAttendant" [@fadeInOut]>
            <button *ngIf="attendant.validated === false" pButton pRipple type="button" pTooltip="Confirmar"
              tooltipPosition="top" class="p-button-text" icon="pi pi-check-circle"
              (click)="validate(attendant.ticketId)"></button>
            <button *ngIf="attendant.validated === true" pButton pRipple type="button" pTooltip="Invalidar"
              tooltipPosition="top" class="p-button-text" icon="pi pi-undo"
              (click)="unvalidate(attendant.ticketId)"></button>
          </td>
          <td>
            <i class="pi pi-angle-right"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">Todavía no hay asistentes.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="col-4 detail-panel-container">
    <app-attendant-detail-panel [eventId]=" eventId" [selectedAttendant]="selectedAttendant" (assistantDeleted)="onAssistantDeleted($event)">
    </app-attendant-detail-panel>
  </div>

  <p-sidebar [(visible)]="showInvite" position="right" [baseZIndex]="10000" styleClass="custom-sidebar"
    [style]="{ width: '50vw' }" (onHide)="cancelInvite()">
    <ng-template pTemplate="header">
      <h3>Invitar asistentes</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form [formGroup]="inviteForm" class="p-fluid">
        <div class="p-field-checkbox">
          <p-checkbox
            formControlName="notifyUser"
            binary="true"
            inputId="notifyUser"
            label="Quiero enviar una notificación a los usuarios seleccionados para que sepan que están siendo invitados"
          ></p-checkbox>
        </div>
        <!-- Invitation Type Selector -->
        <div class="field mt-5">
          <span class="p-float-label">
            <p-dropdown [options]="invitationTypes" formControlName="invitationType" placeholder="Selecciona un tipo"
              optionLabel="name" optionValue="id"></p-dropdown>
            <label for="invitationType">Tipo de Invitación</label>
          </span>
        </div>
        <div class="field mt-5">
          <span class="p-float-label">
            <p-dropdown [options]="getFilteredTicketTypes()" appendTo="body" formControlName="ticketType"
              optionLabel="name" optionValue="id" placeholder="Selecciona una opción">
              <ng-template let-type pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>
                    <p>{{ type.name }}</p>
                    <p class="sub">
                      {{ type.ticketsNumber - type.ticketsSold }} disponibles
                    </p>
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <label for="name" class="block">Tipo de entrada</label>
          </span>
        </div>
        <!-- Conditional UI based on the selected invitation type -->
        <section *ngIf="inviteForm.get('invitationType').value === 'individual'">
          <div class="field mt-5">
            <span class="p-float-label">
              <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="results" (completeMethod)="search($event)"
                (onSelect)="selectResult($event)" field="email">
                <ng-template let-value pTemplate="item">
                  <div class="flex flex-column">
                    <span class="form-header">{{value.fullName}}</span>
                    <span class="form-label">{{value.email}}</span>
                  </div>
                </ng-template>
              </p-autoComplete>
              <label for="name" class="form-label">Buscar usuario por email</label>
            </span>
          </div>
        </section>
        <section *ngIf="inviteForm.get('invitationType').value === 'bulk'">
          <div>
            <h4>Paso 1: Prepara la subida</h4>
            <p style="color: red">Asegúrate que el número de asistentes es menor o igual al número de entradas
              disponibles.</p>
            <p>Formatos permitidos: csv</p>
            <p>Cabecera del CSV: name,surname1,surname2,email,phone</p>
            <p>Campos obligatorios: email</p>
            <button pButton pRipple label="Descarga la plantilla CSV" icon="pi pi-download" type="button"
              class="p-button-text" (click)="downloadTemplate()"></button>
          </div>
          <div>
            <h4>Paso 2: Sube tu fichero</h4>
            <div class="field">
              <p-fileUpload name="myfile[]" mode="basic" accept="text/csv" chooseLabel="Seleccionar fichero"
                [auto]="true" [customUpload]="true" (uploadHandler)="myUploader($event)"></p-fileUpload>
              <p *ngIf="file">Archivo seleccionado: {{ file?.name }}</p>
            </div>
          </div>
        </section>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button (click)="confirmInvite(inviteForm.get('invitationType').value)" label="Enviar invitacion"
        icon="pi pi-send" styleClass="p-button" [disabled]="!inviteForm.valid"></p-button>
    </ng-template>
  </p-sidebar>
</div>